@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    box-sizing: border-box;
}

.logo-part-img {
    width: 125px;
    object-position: center;
    object-fit: contain;
}

.bg-com {
    background-color: #15171C;
}

.card {
    @apply py-12 px-6 border border-gray-800 rounded-lg overflow-hidden flex items-center justify-center flex-col text-slate-200 ;
}

.google-btn {
    @apply px-4 py-2 border flex items-center justify-center gap-2 w-full border-slate-700 rounded-lg text-slate-200 hover:border-slate-500 hover:text-slate-300 hover:shadow transition duration-150;
}

.sperator {
    @apply flex justify-center items-center gap-2;
}

.form-control {
    @apply block w-full px-3 py-3 text-sm font-medium leading-tight text-gray-600 placeholder:text-gray-600 appearance-none bg-transparent border border-gray-800 rounded-md shadow-none hover:border-gray-800 mb-5;
}

.form-control:focus,
.form-control:focus-visible,
.form-control:focus-within,
.form-control:active,
.form-control:target,
.form-control:visited,
.form-control:hover {
    @apply border border-gray-600 outline-none shadow-none text-gray-400;
}

.btn-prim{
    @apply px-5 py-2.5 font-medium bg-indigo-900 hover:bg-indigo-600 hover:text-gray-300 text-gray-300 rounded-md text-sm transition-all ease-linear;
}

.link {
    @apply text-indigo-600 font-medium;
}

input[type="date"] {
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

input[type=file]{
    padding: 0;
}
input[type=file]::file-selector-button {
    background-color: #fff;
    color: #000;
    border: 0px;
    border-right: 1px solid #e5e5e5;
    padding: 10px 15px;
    margin-right: 20px;
    transition: .5s;
}

input[type=file]::file-selector-button:hover {
    background-color: #eee;
    border: 0px;
    border-right: 1px solid #e5e5e5;
}

.spinner {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: #474bff;
    -webkit-mask: radial-gradient(circle closest-side at 50% 40%,#0000 94%, #000);
    transform-origin: 50% 40%;
    animation: spinner 1s infinite linear;
 }
 @keyframes spinner {
    100% {
       transform: rotate(1turn);
    }
 }


 /* barcode scanner */
 div#reader {
    background: #fff;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
}
div#reader__scan_region {
    display: flex;
    align-items: center;
    justify-content: center;
}
div#reader__dashboard_section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
span#html5-qrcode-anchor-scan-type-change ,button#html5-qrcode-button-camera-permission{
    @apply px-4 py-2 font-medium bg-indigo-900 hover:bg-indigo-600 hover:text-gray-300 text-gray-300 rounded-md text-[0.75rem] transition-all ease-linear;
}