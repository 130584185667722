*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-image: url('../public/assets/img/bg10-dark.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  min-height: 100dvh;
}
